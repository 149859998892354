/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Custom color theme properties */
:root {
  /* --f7-theme-color: #2f4052;
  --f7-theme-color-rgb: 47, 64, 82;
  --f7-theme-color-shade: #202c38;
  --f7-theme-color-tint: #3e546c; */
  --sc-bg-color: #2f4052;
  --f7-login-screen-content-bg-color: var(--sc-bg-color);
  --f7-login-screen-bg-color: var(--sc-bg-color);
  --f7-bars-link-color: #fff;
  --f7-bars-bg-color: var(--sc-bg-color);
  --f7-bars-text-color: #fff;
  --f7-navbar-subtitle-text-color: #fff;
  --f7-tabbar-link-inactive-color: rgba(255, 255, 255, 0.54);
  --f7-tabbar-link-active-color: var(--f7-bars-link-color);
  --f7-tabbar-link-active-border-color: var(--f7-bars-link-color);
  --f7-calendar-week-header-bg-color: #8e8e93;
  --f7-photobrowser-bars-bg-color: #8e8e93;
  --f7-photobrowser-bars-text-color: var(--f7-bars-text-color);
  --f7-photobrowser-bars-link-color: var(--f7-bars-link-color);
}
.sc-bg-color {
  background-color: var(--sc-bg-color);
}
.md .navbar-inner {
  position: relative; /* for preloader */
}

.card-content img,
video {
  width: 100%;
  display: block;
}

.list-group ul {
  padding-left: 0% !important;
}
/* Your app custom styles here */
